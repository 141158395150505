// extracted by mini-css-extract-plugin
export var hero = "index-module--hero--igj2E";
export var textSection = "index-module--textSection--HleES";
export var imageSection = "index-module--imageSection--bBMd4";
export var textSection__text = "index-module--textSection__text--vcNJq";
export var textSection__subtext = "index-module--textSection__subtext--TnJYo";
export var imageSection__image = "index-module--imageSection__image--oxJ+7";
export var image = "index-module--image--mTWLZ";
export var herolink = "index-module--herolink--msr1b";
export var herolink__join = "index-module--herolink__join--QZWwM";
export var herolink__find = "index-module--herolink__find--iH2zb";
export var cta_btn = "index-module--cta_btn--RirRc";
export var achievement = "index-module--achievement--cO0PC";
export var achievementItem = "index-module--achievementItem--KH1vE";
export var achievementItem__textSection = "index-module--achievementItem__textSection--Q0b3o";
export var achievementItem__text = "index-module--achievementItem__text--EMG-S";
export var achievementItem__subtext = "index-module--achievementItem__subtext--ak+83";
export var about = "index-module--about--6cOvp";
export var about__link = "index-module--about__link--4D908";
export var about__title = "index-module--about__title--a4tqX";
export var about__article = "index-module--about__article--4mvBQ";
export var about__textSection = "index-module--about__textSection--74WQi";
export var about__imageSection = "index-module--about__imageSection--NPuQb";
export var benefitsContainer = "index-module--benefitsContainer--pb-vz";
export var benefits = "index-module--benefits--oJYwv";
export var benefits__title = "index-module--benefits__title--F70Dr";
export var benefits__subtitle = "index-module--benefits__subtitle--Ww7p6";
export var benefits__paragraph = "index-module--benefits__paragraph--QiZD2";
export var benefits__point = "index-module--benefits__point--lKNh-";
export var benefits__link = "index-module--benefits__link--7qoWj";
export var benefitsOutline = "index-module--benefitsOutline--OpLfe";
export var publications = "index-module--publications--km6CM";
export var book = "index-module--book--YqRQ3";
export var book__item = "index-module--book__item--5lSsC";
export var book__cropper = "index-module--book__cropper--dTBXL";
export var book__title = "index-module--book__title--PQw-8";
export var book__subtitle = "index-module--book__subtitle--62Rpf";