import React from 'react';
import Carousel from 'react-material-ui-carousel';

import image1 from '../svgs/hero-image.svg';
import image2 from '../images/carousel1.png';
import image3 from '../images/carousel2.png';
import image4 from '../images/carousel3.png';

const images = [image1, image2, image3, image4];

export default function CarouselComponent() {
  return (
    <div>
      <img src={image1} alt="caurosel" style={{ width: '100%' }} />
    </div>
  );
}

function CarouselImages({ image }) {
  return (
    <div>
      <img src={image} alt="caurosel" style={{ width: '100%' }} />
    </div>
  );
}
