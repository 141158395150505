import React from 'react';
import { Link } from 'gatsby';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import * as styles from './index.module.scss';
import Layout from '../components/layout';
import Carousel from '../components/carousel';
import reward from '../svgs/reward.svg';
import increase from '../svgs/increase.svg';
import happiness from '../svgs/happiness.svg';
import who_we_are from '../svgs/illustrator.svg';
import workshop from '../svgs/workshop.svg';
import presentation from '../svgs/presentation.svg';
import advisor from '../svgs/advisor.svg';
import organization from '../svgs/organization.svg';

function Hero() {
  return (
    <div className={styles.hero}>
      <div
        className={styles.textSection}
        // data-aos="fade-up"
        // data-aos-delay="300"
        // data-aos-duration="1000"
      >
        <h2 className={styles.textSection__text}>
          Welcome to the Association of Children’s Authors and Illustrators of
          Nigeria
        </h2>
        <p className={styles.textSection__subtext}>
          The network for professional and aspiring children's book writers and
          Illustrators of Nigerian heritage.
        </p>
        <div className={styles.herolink}>
          <Link to="/types-of-membership" className={styles.herolink__join}>
            Join Us now
          </Link>
          <Link to="/about-us" className={styles.herolink__find}>
            Find out more
          </Link>
        </div>
      </div>

      <div
        className={styles.imageSection}
        // data-aos="fade-up"
        // data-aos-easing="ease-in-back"
        // data-aos-delay="50"
        // data-aos-duration="1000"
      >
        {/* <img src={hero_img} alt="hero" className={styles.imageSection__image} /> */}
        <Carousel />
      </div>
    </div>
  );
}

function Achievement() {
  const achievementDta = {
    happiness: {
      image: happiness,
      text: 15,
      subtext: 'Happy Members'
    },
    achievement: {
      image: reward,
      text: 150,
      subtext: 'Achievement'
    },
    benefits: {
      image: increase,
      text: 50,
      subtext: 'Benefits'
    }
  };

  return (
    <div className={styles.achievement}>
      {Object.values(achievementDta).map((item, idx) => (
        <AchievementItem
          key={idx}
          image={item.image}
          text={item.text}
          subtext={item.subtext}
        />
      ))}
    </div>
  );
}

function AchievementItem({ image, text, subtext }) {
  return (
    <CountUp start={0} end={text} redraw={true} suffix="+">
      {({ countUpRef, start }) => (
        <VisibilitySensor onChange={start} delayedCall>
          <div className={styles.achievementItem}>
            <img src={image} alt="happiness icon" />
            <div className={styles.achievementItem__textSection}>
              <div className={styles.achievementItem__text} ref={countUpRef} />

              <span className={styles.achievementItem__subtext}>{subtext}</span>
            </div>
          </div>
        </VisibilitySensor>
      )}
    </CountUp>
  );
}

function About() {
  return (
    <div className={styles.about}>
      <div
        className={styles.about__textSection}
        // data-aos="fade-up"
        // data-aos-delay="50"
        // data-aos-duration="1000"
        // data-aos-anchor-placement="bottom-center"
      >
        <h3 className={styles.about__title}>Who We Are</h3>
        <article className={styles.about__article}>
          ACAIN exists to answer the questions “Where can I find the support I
          need to improve my creativity as a children’s book author, illustrator
          and editor as well as thrive from it?”
        </article>

        <div className={styles.about__link}>
          <Link to="/about-us" className={styles.cta_btn}>
            View more
          </Link>
        </div>
      </div>

      <div
        className={styles.about__imageSection}
        // data-aos="fade-down-left"
        // data-aos-delay="50"
        // data-aos-duration="1000"
        // data-aos-anchor-placement="bottom-center"
      >
        <img src={who_we_are} alt="An illustrator" className={styles.image} />
      </div>
    </div>
  );
}

export default function () {
  // const user = useContext(UserContext)
  // console.log(user);
  return (
    <Layout>
      <Hero />
      <Achievement />
      <About />

      <div className={styles.benefitsContainer}>
        <div className={styles.benefits}>
          <h3 className={styles.benefits__title}>Membership Benefit</h3>

          <div className={styles.benefitsOutline}>
            <div
              className={styles.benefits__point}
              // data-aos="fade-right"
              // data-aos-delay="50"
              // data-aos-duration="1000"
              // data-aos-anchor-placement="bottom-center"
            >
              <div>
                <h4 className={styles.benefits__subtitle}>
                  Training and Workshops
                </h4>
                <p className={styles.benefits__paragraph}>
                  Members gain valuable technical skills, entrepreneurial skills
                  and knowledge to help them improve their craft
                </p>
              </div>
              <img src={workshop} alt="workshop icon" />
            </div>

            <div
              className={styles.benefits__point}
              // data-aos="fade-left"
              // data-aos-delay="60"
              // data-aos-duration="1000"
              // data-aos-anchor-placement="bottom-center"
            >
              <div>
                <h4 className={styles.benefits__subtitle}>
                  Information services
                </h4>
                <p className={styles.benefits__paragraph}>
                  Members are able to access the directory of technical skill
                  providers in order to accelerate and improve the quality of
                  their work. The directory would include resource providers
                  such as publishers, printers, distributors, illustrators and
                  editors
                </p>
              </div>
              <img src={presentation} alt="presentation icon" />
            </div>

            <div
              className={styles.benefits__point}
              // data-aos="fade-right"
              // data-aos-delay="70"
              // data-aos-duration="1000"
              // data-aos-anchor-placement="bottom-center"
            >
              <div>
                <h4 className={styles.benefits__subtitle}>Advisory services</h4>
                <p className={styles.benefits__paragraph}>
                  Members are able to access the directory of technical skill
                  providers in order to accelerate and improve the quality of
                  their work. The directory would include resource providers
                  such as publishers, printers, distributors, illustrators and
                  editors
                </p>
              </div>
              <img src={advisor} alt="advisor icon" />
            </div>

            <div
              className={styles.benefits__point}
              // data-aos="fade-left"
              // data-aos-delay="80"
              // data-aos-duration="1000"
              // data-aos-anchor-placement="bottom-center"
            >
              <div>
                <h4 className={styles.benefits__subtitle}>
                  Network and access
                </h4>
                <p className={styles.benefits__paragraph}>
                  Members are able to access the directory of technical skill
                  providers in order to accelerate and improve the quality of
                  their work. The directory would include resource providers
                  such as publishers, printers, distributors, illustrators and
                  editors
                </p>
              </div>
              <img src={organization} alt="organization icon" />
            </div>
          </div>

          <div
            className={styles.benefits__link}
            // data-aos="zoom-out-up"
            // data-aos-delay="90"
            // data-aos-duration="1000"
            // data-aos-anchor-placement="bottom-center"
          >
            <Link to="/membership-benefits" className={styles.cta_btn}>
              View more
            </Link>
          </div>
        </div>
      </div>

      <div className={styles.publications}>
        <h3 className={styles.benefits__title}>Publications</h3>

        <div className={styles.book}>
          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237816/ACAIN/Bookpeddler-books-Whose-favorite-am-I_-300x417_wmqtpd.jpg"
                alt="Whose Favourite am I?"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>Whose Favourite am I?</div>
            <span className={styles.book__subtitle}>Chigozie A. Mbadugha</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237854/ACAIN/81QrrGpcyiL_t5cvgz.jpg"
                alt="A Visit to Grandad: An African"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>
              A Visit to Grandad: An African ABC
            </div>
            <span className={styles.book__subtitle}>
              Sade Fadipe &amp; Shedrach Ayalomeh
            </span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237819/ACAIN/IMG-20220209-WA0001_ud0eur.jpg"
                alt="I am a Nigerian"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>I am a Nigerian (series)</div>
            <span className={styles.book__subtitle}>Foluke Oyeleye</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237818/ACAIN/Screenshot_20220324-195638_Drive_d2ygjd.jpg"
                alt="The Greedy Ostrich"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>The Greedy Ostrich</div>
            <span className={styles.book__subtitle}>Olusayo Ajetunmobi</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237818/ACAIN/IMG-20220112-WA0014-1_kln4na.jpg"
                alt="Ebun the Exotic Elephant"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>Ebun the Exotic Elephant</div>
            <span className={styles.book__subtitle}>
              Oyinda Kazeem &amp; Olamide Fayemi
            </span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237818/ACAIN/IMG-20220203-WA0007_v3gmij.jpg"
                alt="Mma Made"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>Mma Made</div>
            <span className={styles.book__subtitle}>
              Chinyere Evelyn Ifediora
            </span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237817/ACAIN/IMG-20211119-WA0005-1_r20pdd.jpg"
                alt="Pamima's Notebook"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>Pamima's Notebook</div>
            <span className={styles.book__subtitle}>Diseph Ruth Otto</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1649237817/ACAIN/doing-right-cover-design_by_Olayemi_Ejemai-1_bl1fvd.jpg"
                alt="Doing Right: One good turn deserves another"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>
              Doing Right: One good turn deserves another
            </div>
            <span className={styles.book__subtitle}>Olayemi Ejemai</span>
          </div>

          <div className={styles.book__item}>
            <div className={styles.book__cropper}>
              <img
                src="https://res.cloudinary.com/ojay-dev/image/upload/v1652063317/ACAIN/Abdulkareem_Baba_Aminu_lhieqx.jpg"
                alt="The Most Important Comic Book on Earth"
                className={styles.image}
              />
            </div>
            <div className={styles.book__title}>
              The Most Important Comic Book on Earth
            </div>
            <span className={styles.book__subtitle}>
              Abdulkareem Baba-Aminu
            </span>
          </div>
        </div>

        <div className={styles.benefits__link} style={{ marginTop: '4rem' }}>
          <Link to="/publications" className={styles.cta_btn}>
            View more
          </Link>
        </div>
      </div>
    </Layout>
  );
}
